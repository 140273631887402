<template>
	<div class="ysm-order-jindu">
		<!-- 第一部分 -->
		<div class="ordertop">
			<div class="con">
				<div class="conleft">
					<span>订&nbsp;单&nbsp;ID&nbsp;号:&nbsp;&nbsp;&nbsp;&nbsp;{{jinduinfo.orderNum}}</span>
					<h3 v-show="vis==false" style="padding-left: 50px;color:red">{{jinduinfo.statusString}}</h3>
					<h3 v-show="vis" style="padding-left: 50px;">{{jinduinfo.statusString}}</h3>
				</div>
				<div v-show="vis" class="conright">
					<el-steps :active="stepIndex" align-center finish-status="success">
						<el-step title="提交订单" description=""></el-step>
						<el-step title="审核通过" description=""></el-step>
						<el-step title="方案确认" description=""></el-step>
						<el-step title="付款" description=""></el-step>
						<el-step title="发货" description=""></el-step>
						<el-step title="订单完成" description=""></el-step>
					</el-steps>
				</div>
			</div>
		</div>
		<div style="width: 100%;background-color: white;">
		<div class="order">
			<div class="orderbottomleft">
				<div align="left" class="addressInfo">收货信息</div>
				<table style="width: 100%;" >
					<tr align="left">
						<td>联系人: </td>
						<td class="td1">{{jinduinfo.address.userName}}&nbsp;&nbsp;&nbsp;{{jinduinfo.address.phone}}</td>
					</tr>
					<tr align="left">
						<td>收件地址: </td>
						<td class="td1">{{jinduinfo.address.cityInfo}}{{jinduinfo.address.address}}</td>
					</tr>
					<tr v-if="(jinduinfo.stepNum+1)>=4" align="left">
						<td>付款时间: </td>
						<td v-if="jinduinfo.payTime!=null" class="td1">{{jinduinfo.payTime}}</td>
							<td v-if="jinduinfo.payTime==null" class="td1">暂无</td>
					</tr>
					<tr v-if="(jinduinfo.stepNum+1)>=5" align="left">
						<td>发货方式: </td>
						<td v-if="jinduinfo.expressCompany!=null" class="td1">{{jinduinfo.expressCompany}}</td>
						<td v-if="jinduinfo.expressCompany==null" class="td1">暂无</td>
					</tr>
					<tr v-if="(jinduinfo.stepNum+1)>=5" align="left">
						<td>货运单号: </td>
						<td v-if="jinduinfo.expressNum!=null" class="td1">{{jinduinfo.expressNum}}</td>
						<td v-if="jinduinfo.expressNum==null" class="td1">暂无</td>
					</tr>
				</table>
				<div v-if="jinduinfo.stepNum==5">
				<div v-if="jinduinfo.expressCompany!=null" class="confirmGoods">
					<span>您还有<span style="color: red;">{{jinduinfo.timeSpan}}</span>来确认收货,超市订单自动确认收货</span>
					<el-button class="nextStep" type="primary" @click="confirmGoods">确认收货</el-button>
				</div>
				</div>
			</div>
			<!-- 右边 -->
			<div class="orderbottomright">
				<div align="left" class="orderJinChengInfo">订单进程记录</div>
				<div style="overflow-y: auto;height: 90%;">
					<table style="width: 100%;" class="jindutable">
						<tr v-for="(item,index) in jinduinfo.slotList">
							<td align="left">{{item.title}}</td>
							<td align="left">{{item.actionTime}}</td>
							<td align="left">{{item.content}}</td>
							<td align="right">{{item.actionName}}</td>
						</tr>
					</table>
					<!-- <div style="display: flex;align-items: center; justify-content: space-between;" align="left"
						v-for="(item,index) in jinduinfo.slotList">
						<span>{{item.title}}</span>
						<span>{{item.actionTime}}</span>
						<span>{{item.content}}</span>
						<span>{{item.actionName}}</span>
					</div> -->
				</div>
			</div>


		</div>
		</div>
		<div class="f">111111111</div>
	</div>
</template>

<script>
	import {
		Message
	} from 'element-ui';
	export default {
		data: function() {
			return {
				vis:true,
				ruleForm: {
					oid: ""
				},
				jinduinfo: {},
				stepIndex: 0

			}
		},
		methods: {
			//确认收货
			confirmGoods() {
				this.$axios.get("/client/order/completeOrder", {
					params: {
						oid: this.ruleForm.oid
					}
				}).then(res => {
					if(res.data.code==200){
						this.$alert('确认收货成功', '提示', {
							confirmButtonText: '确定',
							callback: action => {
								this.$router.push("/ysmmain/ysmlatestorder")
							}
						});
					}
					

				}).catch(err => {
					this.$message({
						type: "error",
						message: "请联系管理员",
						duration: 1000,
						offset: 300
					});
					//this.$router.push("/ysmmain/ysmlatestorder")
				});
			}


		},
		mounted: function() {

		},
		created: function() {
			this.ruleForm.oid = sessionStorage.getItem("oid");
			this.$axios.get("/client/order/getOrderRecord", {
				params: {
					oid: this.ruleForm.oid
				}
			}).then(res => {
				console.log(res.data);
				this.jinduinfo = res.data.data;
				if (this.jinduinfo.stepNum != null) {
					this.stepIndex = this.jinduinfo.stepNum - 1;
				}
				if (res.data.code == 200) {

				}
				if(res.data.data.statusString=="订单已终止"){
					this.vis=false;
				}

			}).catch(err => {
				this.$message({
					type: "error",
					message: "请联系管理员",
					duration: 1000,
					offset: 300
				});
				//this.$router.push("/ysmmain/ysmlatestorder")
			});
			// this.conditionForm.doctorId = JSON.parse(sessionStorage.getItem("loginInfo")).id;

			//将当前路径存储到backLocation
			this.$store.commit("setBackLocation", "/ysmmain/ysmlatestorder");
		}
	}
</script>

<style scoped="scoped">
	.ysm-order-jindu {
		width: 100%;
		height: 100%;
		background-color: transparent;
		overflow-y: auto;
		position: relative;
		margin-bottom: 1.875rem;

	}

	.ordertop {
		margin-top: 15.8rem;
		height: 200px;
		background-color: white;
		padding-top: 0.5rem;
	}

	.order {
		position: relative;
		background-color: white;
		width: 90%;
		height: 31.75rem;
		margin: 0px auto;
		/* margin-top: 18.75rem; */
		margin-top: 1.5rem;
		margin-bottom: 38.75rem;
		padding-top: 0.5rem;
		display: flex;
		justify-content: space-between;
	}

	.con {
		width: 90%;
		height: 100%;
		border-top: 5px solid #af9f73 !important;
		margin: 0px auto;

	}

	.conleft {
		float: left;
		text-align: left;
		width: 350px;

	}

	.conright {
		padding-top: 3.25rem;

	}

	.addressInfo {
		font-weight: bolder;
		border-bottom: 3px solid #af9f73;
		padding-bottom: 5px;
		margin-bottom: 5px;
	}

	.orderbottomleft {
		height: 100%;
		width: 350px;
		/* margin-left: 4.25rem; */
		flex: 1;
		
	}

	.confirmGoods {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 340px;
		height: 120px;
		background-color: #F0F0EE;
		border-radius: 5px;
		white-space: pre-wrap;
		margin-top: 50px;
	}

	/* 按钮 */
	.nextStep {
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		min-width: 7.5rem;
		margin-top: 10px;
	}

	/* 订单进程记录 */
	.orderbottomright {
		width: 500px;
		margin-left: 6.25rem;
		flex: 3;
	}

	.orderJinChengInfo {
		font-weight: bolder;
		border-bottom: 3px solid #af9f73;
		padding-bottom: 5px;
		margin-bottom: 5px;
	}

	h3 {
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #af9f73;
		margin-bottom: 20px;
		font-size: 35px;
	}


	.primary {
		margin: 0px 0px 1px;
		padding: 6px 12px 6px 12px;
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		border-radius: 0px;
		width: 5.125rem;
		height: 2.3rem;
	}
	.orderbottomleft table td{
		
		text-align: left;
		padding: 0px;
		display: inline-block;
		width: 100px;
		
	}
	.orderbottomleft table .td1{
		width: 230px;
	}
	
	.jindutable tr td{
		width: 3px;
		line-height: 30px;
	}
</style>
